import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'

const S1Careers = () => {
  const data = useStaticQuery(graphql`
    query S1CareersQuery {
      contentfulGenericHeadingTextAndIcon (
        contentIdentifier: { eq: "S1Careers" }
      ) {
        header {
          childMarkdownRemark {
            html
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <ContentContainer maxWidth="960px">
      <S1CareersWrapper id="careers">
        <h2>
          <span className="greenHeader">SocietyOne </span>
          Careers
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentfulGenericHeadingTextAndIcon.header.childMarkdownRemark.html,
          }}
        />
        <div
          className="vacancy"
          dangerouslySetInnerHTML={{
            __html: data.contentfulGenericHeadingTextAndIcon.content.childMarkdownRemark.html,
          }}
        />
      </S1CareersWrapper>
    </ContentContainer>
  )
}

const S1CareersWrapper = styled.div`
  margin-top: -120px;
  padding-top: 120px;
  text-align: center;
  .vacancy {
    margin-top: 70px;
    padding: 10px;
    background-color: #f4f5f6;
  }
  h2 {
    margin-bottom: 40px;
  }
`

export default S1Careers
