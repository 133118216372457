import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../layouts/layout'
import Seo from '../../components/seo'
import WeAreSocietyOne from '../../components/UI/HeaderAndImageOnBottom'
import S1Careers from '../../components/ForAboutUs/S1Careers'
import { parseJson } from '../../support'

const Careers = ({ path }) => {
  const queriedStructuredDataConfigForCareers = useStaticQuery(graphql`
    query structuredDataConfigForCareersQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "about-us/careers" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForCareers.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  return (
    <Layout>
      <Seo 
        title="Our Jobs & Careers"
        description="SocietyOne welcomes forward-thinking people who share our passion for creating brilliant experiences. Join us, and together we will do amazing things." 
        path={path}
        structuredData={structuredData}
      />
      <WeAreSocietyOne slugQuery="/about-us/careers" />
      <S1Careers />
    </Layout>
  )
}

export default Careers