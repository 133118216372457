import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'

import { BUTTON_COLOR, BUTTON_SIZE, ABOUT_LINKS } from '../../support'
import HomeButton from './Buttons/HomeButton'
import TabNav from './Navigation/TabNav'
import ContentContainer from './ContentContainer'

const HeaderAndImageOnBottom = ({ slugQuery }) => {
  const allHeaderAndImageOnBottomCompsQuery = useStaticQuery(graphql`
    query allHeaderAndImageOnBottomCompsQuery {
      allContentfulComponentHeaderAndImageOnBottom {
        nodes {
          header
          greenHeader
          subHeader
          buttonText
          elementOnClick
          slug
          image {
            gatsbyImageData(placeholder:NONE)
            title
          }
        }
      }
    }
  `)

  return allHeaderAndImageOnBottomCompsQuery.allContentfulComponentHeaderAndImageOnBottom.nodes.map(
    (c, i) =>
      c.slug === slugQuery ? (
        <div key={i}>
          <ContentContainer>
            <div className="text-center">
              <h1>
                {`${c.header} `}
                <span className="greenHeader">{ c.greenHeader }</span>
              </h1>
              <p>{ c.subHeader }</p>
            </div>
            <HomeButton
              height={BUTTON_SIZE.HEIGHT_L}
              padding={BUTTON_SIZE.PADDING_L}
              fontSize={BUTTON_SIZE.FONT_SIZE_L}
              background={BUTTON_COLOR.GREEN}
              backgroundHover={BUTTON_COLOR.GREEN_HOVER}
              color={BUTTON_COLOR.TEXT_COLOR_WHITE}
              text={ c.buttonText }
              handleClick={() => scrollTo(`#${c.elementOnClick}`)}
            />
          </ContentContainer>
          <MainImg>
            <GatsbyImage
              image={c.image?.gatsbyImageData}
              alt={c.image.title}
            />
          </MainImg>
          <TabNav
            allLinks={ABOUT_LINKS}
            background="var(--base-gray)"
          />
        </div>
      ) : null
  )
}

const MainImg = styled.div`
  text-align: center;
  position: relative;
  padding: 0;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
  }
`

export default HeaderAndImageOnBottom