import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const TabNav = ({ allLinks, background }) => {
  const tabLinks = () => {
    const links = []
    allLinks.forEach((l, i) => {
      links.push(
        <Link to={l.to} key={i} activeClassName="active" >
          { l.text } 
        </Link>
      )
    })
    return links
  }

	return (
    <Wrapper background={background}>
      {tabLinks()}
    </Wrapper>
	)
}

const Wrapper = styled.div`
  padding: 32px 40px;
  background-color: ${props => props.background || "var(--white)"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  a {
    font-family: QuicksandBold;
    font-size: 18px;
    padding: 12px 24px;
    border: 1px solid #d4dbe0;
    border-radius: 10px;
    background-color: #fff;
    font-size: 18px;
    margin: 0 10px;
    text-align: center;
    &:hover {
      color: inherit;
    }
  }
  .active {
    color: var(--white);
    background-color: var(--base-navy);
    &:hover {
      color: var(--white) !important;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
    a {
      width: 100%;
      justify-content: center;
      margin: 8px 0 !important;
    }
  }
`

export default TabNav